<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Danh sách sản phẩm yêu cầu XNK'">
          <!-- <template v-slot:toolbar>
            <div class="row">
              <router-link to="/stocks/add-stock">
                <button
                  v-bind:style="btnCreate"
                  type="button"
                  class="btn btn-primary font-weight-bolder btn-sm"
                >
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo
                  phiếu nhập kho
                </button>
              </router-link>
            </div>
          </template>-->
          <template v-slot:preview>
            <!-- Header session -->
            <b-row class="mb-5">
              <b-col>
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control-sm"
                  :config="dpConfigs.start"
                  v-model="dpForm.startDate"
                ></date-picker>
              </b-col>
              <b-col>
                <date-picker
                  placeholder="Đến ngày"
                  class="form-control-sm"
                  :config="dpConfigs.end"
                  v-model="dpForm.endDate"
                ></date-picker>
              </b-col>
              <b-col>
                <vue-autosuggest
                  v-model="searchProduct"
                  :suggestions="filteredOptionsProduct"
                  @selected="onSelectedProduct"
                  :limit="10"
                  @input="onInputChangeProduct"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: 'Nhập mã, tên, mã vạch sản phẩm',
                  }"
                  :should-render-suggestions="
                    (size, loading) =>
                      size >= 0 && !loading && searchProduct !== ''
                  "
                  ><div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    {{ suggestion.item.productName }}
                  </div>
                </vue-autosuggest>
              </b-col>
              <b-col>
                <div class="col">
                  <div class="row">
                    <b-lable class="mr-2 d-flex align-items-center"
                      >Trạng thái:</b-lable
                    >
                    <b-form-select
                      size="sm"
                      required
                      v-model="selectedStatus"
                      :options="listStatus"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                      class="col-md-8"
                    ></b-form-select>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <b-form-input
                  v-model="search"
                  type="text"
                  placeholder="Tìm kiếm"
                  size="sm"
                  v-on:keyup.enter="onFilter()"
                ></b-form-input>
              </b-col>
              <b-col>
                <b-form-input
                  v-model="searchId"
                  type="text"
                  placeholder="Mã phiếu"
                  size="sm"
                  v-on:keyup.enter="onFilter()"
                ></b-form-input>
              </b-col>
              <b-col>
                <vue-autosuggest
                  v-model="searchStock"
                  :suggestions="filteredOptions"
                  @selected="onSelected"
                  :limit="10"
                  @input="onInputChange"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: 'Kho nguồn',
                  }"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <div style="{ display: 'flex', color: 'navyblue'}">
                      {{ suggestion.item.suggestionName }}
                    </div>
                  </div>
                </vue-autosuggest>
              </b-col>
              <b-col>
                <vue-autosuggest
                  v-model="searchDestinationStock"
                  :suggestions="filteredDStockOptions"
                  @selected="onDestinationSelected"
                  :limit="10"
                  @input="onSearchDestinationChange"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: 'Kho đích',
                  }"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <div style="{ display: 'flex', color: 'navyblue'}">
                      {{ suggestion.item.suggestionName }}
                    </div>
                  </div>
                </vue-autosuggest>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col md="1">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter"
                  >Lọc</b-button
                >
              </b-col>
            </b-row>
            <!-- End of Header session -->
            <!-- Table session -->
            <b-table
              :items="stocks"
              :fields="fields"
              sort-by="count"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
              </template>

              <template v-slot:cell(count)="row">
                <div style="text-align: center">
                  <span v-text="row.item.count" class="text-right"></span>
                </div>
              </template>
              <template v-slot:cell(productName)="row">
                <p
                  v-if="row.item.productCode"
                  style="font-size: 12px; font-weight: 500; margin-bottom: 0"
                >
                  ( {{ row.item.productCode }} )
                </p>
                <span
                  v-text="row.item.productName"
                  style="white-space: normal"
                ></span>
              </template>
              <template v-slot:cell(quantity)="row">
                <div style="text-align: right">
                  <span v-text="row.item.quantity" class="text-right"></span>
                </div>
              </template>

              <template v-slot:cell(code)="row">
                <div
                  style="
                    justify-content: center;
                    align-items: center;
                    display: grid;
                  "
                >
                  <span v-text="row.item.code"></span>
                </div>
              </template>
              <template v-slot:cell(createdAt)="row">
                <p class="text-center">{{ row.item.createdAt }}</p>
                <p class="text-center">{{ row.item.createBy }}</p>
              </template>

              <template v-slot:cell(approve)="row">
                <div v-if="row.item.status === 2">
                  <p class="text-center text-primary mb-0">Đã duyệt</p>
                  <p class="text-center">{{ row.item.approveBy }}</p>
                  <p class="text-center text-primary mb-0">
                    {{ row.item.approveDate }}
                  </p>
                </div>
              </template>

              <template v-slot:cell(sourceWarehouseId)="row">
                <div class="font-weight-bolder">
                  <span v-text="row.item.sourceWarehouseId"></span>
                  <br />
                  <i
                    style="font-size: 1rem; color: blue"
                    class="flaticon2-right-arrow"
                  ></i>
                  <br />
                  <span v-text="row.item.destinationWarehouseId"></span>
                </div>
              </template>

              <!-- <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center" v-if="false">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i style="font-size: 1rem;padding-right: 0px;" class="flaticon2-settings"></i>
                    </template>
                    <b-dropdown-item @click="editItem(row.item)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="showDeleteAlert(row.item)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>-->
            </b-table>
            <!-- End of Table session -->

            <!-- Paginate session -->
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng :
                  {{ totalItems }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="customPagination"
                  v-show="totalPages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="totalPages"
                  use-router
                  @change="fetchStock"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
            <!-- End of Paginate session -->
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import moment from 'moment';
import { VueAutosuggest } from 'vue-autosuggest';
import { IMPORT_STOCK } from '../../../utils/constants';
import { vi } from 'vuejs-datepicker/dist/locale';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import decounce from 'debounce';
import localData from '../../../utils/saveDataToLocal';

export default {
  data() {
    return {
      dpForm: {
        startDate: '',
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      disabledDates: {},
      format: 'dd-MM-yyyy',
      language: vi,
      btnCreate: {
        fontWeight: '600!important',
      },
      stocks: [],
      search: '',
      searchStock: '',
      searchId: '',
      currentPage: 0,
      totalPages: 0,
      count: 1,
      totalItems: 0,
      page: 1,
      status: '',
      store: '',
      searchFromDay: '',
      searchToDay: '',
      type: IMPORT_STOCK,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'sourceWarehouseId',
          label: 'Kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'code',
          label: 'Mã phiếu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
        },
        {
          key: 'productName',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
        },
        {
          key: 'quantity',
          label: 'SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'approve',
          label: 'Duyệt',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      selected: '',
      options: [
        {
          data: [],
        },
      ],
      filteredOptions: [],
      limit: 10,
      searchDestinationStock: '',
      selectedDStock: '',
      optionsDStock: [
        {
          data: [],
        },
      ],
      filteredDStockOptions: [],
      searchProduct: '',
      selectedStatus: 0,
      listStatus: [
        {
          id: 0,
          name: 'Tất cả',
        },
        {
          id: 1,
          name: 'Chưa duyệt',
        },
        {
          id: 2,
          name: 'Đã duyệt',
        },
      ],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
    };
  },
  methods: {
    onEndChange: function () {
      this.onFilter();
    },
    onStartChange: function () {
      this.onFilter();
    },
    fetchStore: async function () {
      this.options[0].data = [];
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options[0].data.push(store);
        });
        this.filteredOptions = [{ data: this.options[0].data }];
        this.filteredDStockOptions = [{ data: this.options[0].data }];
      });
    },
    fetchStock: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      let params = {
        page: this.page,
        pageSize: 10,
        search: this.search,
        searchId: this.searchId,
        status: this.selectedStatus,
        searchFromDay: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 00:00:00'
            )
          : '',
        searchToDay: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 23:59:59'
            )
          : '',
        searchStock: this.searchStock,
        type: 13,
        searchDestinationStock: this.searchDestinationStock,
        searchProduct: this.searchProduct,
      };

      ApiService.setHeader();
      ApiService.query('requireStocks/getAllDetail', { params })
        .then((response) => {
          this.stocks = [];
          const stockResponse = response.data.data.result;
          this.totalPages = response.data.data.totalPages;
          this.currentPage = response.data.data.currentPage;
          this.totalItems = response.data.data.totalItems;
          let result = [];
          stockResponse.forEach((item, index) => {
            let stock = {
              count:
                this.currentPage >= 2
                  ? index + 1 + this.currentPage * 10 - 10
                  : index + 1,
              id: item.id,
              code: item.code,
              sourceWarehouseId: item.sourceWarehouseId,
              destinationWarehouseId: item.destinationWarehouseId,
              providerId: item.providerId,
              description: item.description,
              status: item.status,
              totalAmount: item.totalAmount,
              type: item.type,
              importDate: moment(String(item.importDate)).format('DD/MM/YYYY'),
              exportDate: moment(String(item.exportDate)).format('DD/MM/YYYY'),
              transferDate: moment(String(item.transferDate)).format(
                'DD/MM/YYYY'
              ),
              deleted: item.deleted,
              createBy: item.createBy,
              updateBy: item.updateBy,
              createdAt: moment(String(item.createdAt)).format('DD/MM/YYYY'),
              approveBy: item.approveBy,
              approveDate: moment(String(item.approveDate)).format(
                'DD/MM/YYYY hh:mm:ss'
              ),
              confirmBy: item.confirmBy,
              confirmDate: moment(String(item.confirmDate)).format(
                'DD/MM/YYYY hh:mm:ss'
              ),
              relateStock: item.relateStockSlipId,
              relateStockCode: item.relateStockCode,
              productName: item.productName,
              quantity: item.quantity,
              productCode: item.productCode,
            };
            result.push(stock);
            this.stocks = [...new Set(result)];
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    checkStatus: function (status) {
      if (status === 'Mới') {
        return 1;
      } else if (status === 'Đang xử lý') {
        return 2;
      } else if (status === 'Đã hoàn thành') {
        return 3;
      } else {
        return 0;
      }
    },
    editItem: function (item) {
      this.$router.push({
        path: 'stocks/update-stock',
        query: { id: item.id },
      });
    },
    onSelected(option) {
      this.selected = option.item.name;
      this.searchStock = option.item.name;
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
      //this.fetchStock();
    },
    /**
     * This is what the <input/> value is set to when you are selecting a suggestion.
     */
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa phiếu nhập kho!',
        text: 'Bạn có chắc muốn xóa phiếu nhập kho này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    deleteItem: async function (item) {
      let id = item.id;
      ApiService.setHeader();
      ApiService.delete(`stocks/delete-stock/${id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchStock();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    showApproveAlert: function (id) {
      // Swal.fire({
      //   title: 'Duyệt phiếu yêu cầu xuất chuyển kho!',
      //   text:
      //     'Bạn có chắc muốn duyệt phiếu yêu cầu xuất chuyển kho này không ?',
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: 'rgb(221, 51, 51)',
      //   cancelButtonColor: '#E5EAEE',
      //   confirmButtonText: 'Duyệt',
      //   cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
      //   reverseButtons: true,
      //   showClass: {
      //     popup: 'animate__animated animate__fadeInDown',
      //   },
      //   hideClass: {
      //     popup: 'animate__animated animate__fadeOutUp',
      //   },
      //   position: 'top',
      // }).then((result) => {
      //   if (result.value) {
      //     this.onApproveItem(id);
      //   }
      // });
      this.$router.push({
        name: 'approve-transfer-stock',
        query: { id: id },
      });
    },
    onApproveItem(id) {
      ApiService.setHeader();
      ApiService.put(`stocks/approve-require-stock/${id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchStock();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    showConfirmAlert: function (id) {
      this.$router.push({
        name: 'confirm-transfer-stock',
        query: { id: id },
      });
    },
    onConfirmItem(id) {
      ApiService.setHeader();
      ApiService.put(`stocks/confirm-require-stock/${id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchStock();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    viewTransferStock: function (item) {
      this.$router.push({
        name: 'update-transfer-stock',
        query: { id: item.relateStock },
      });
    },
    onDestinationSelected(option) {
      this.selectedDStock = option.item.name;
      this.searchDestinationStock = option.item.name;
    },
    onSearchDestinationChange(text) {
      if (!text) {
        text = '';
      }
      this.searchDestinationStock = text;

      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredDStockOptions = [
        {
          data: filteredData,
        },
      ];
      // this.fetchStock();
    },
    fetchProduct() {
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch?searchProduct=${this.searchProduct}&storeId=&includeParent=true`
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.fetchStock();
      });
    },
    debounceInput: decounce(function () {
      this.fetchProduct();
    }, 500),
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInput();
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-product-require-to-nhanh',
      });
      this.fetchStock();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Phiếu yêu cầu XNK sang hệ thống cũ',
        route: '/require-stock-to-nhanh',
      },
      { title: 'Danh sách sản phẩm yêu cầu XNK' },
    ]);
  },
  created() {
    let requireCode = this.$route.query.code;
    if (requireCode !== undefined) {
      this.searchId = requireCode;
    }
    this.fetchStock();
    this.fetchStore();
  },
  components: {
    KTCodePreview,
    VueAutosuggest,
    VclTable,
    datePicker,
  },
};
</script>

<style>
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

/* h5 {
  font-size: 100%;
  padding: 0;
} */

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
.productCode:hover {
  text-decoration: underline;
}
</style>
